import React, { useState, useContext, useCallback, useEffect, useMemo } from 'react';
import '@shopify/polaris/build/esm/styles.css';
import { cloneDeep } from 'lodash';
import { t } from 'i18next';

import { PageData } from '../../../ContextAPI/Context';
import { Toast_Message_Type } from '../../../Constant';
import ExitButton from './ExitButton';
import { Button, Toast, Switch, LayoutToggle, Badge, Icon } from '../../../Shared/Components';
import { IconSource } from '../../../Shared/Components/Icon';
import { CreatedNewObj } from '../../../Utils/Common';
import styles from './Header.module.css';

const Header = () => {
  const [toastMessage, setToastMessage] = useState({});
  const {
    configSetting,
    updateData,
    redoConfigSetting,
    submitData,
    undoFun,
    redoFun,
    setHelpModalData,
    requiredData,
    setRequiredData,
    isRequiredDataFilled,
    isMobileView,
    setIsMobileView,
    helpLinks
  } = useContext(PageData);

  const latestSettings = useMemo(() => {
    return cloneDeep(configSetting[configSetting.length - 1]);
  }, [configSetting]);

  const handleChange = useCallback((value) => {
    setIsMobileView(value);
  }, [isMobileView]);

  const handleToggle = (e) => {
    if (isRequiredDataFilled()) {
      let value = e.target.checked;
      let updateSettings = CreatedNewObj(latestSettings);
      updateSettings = { ...updateSettings, status: value }
      updateData(updateSettings);
    }
  };

  const handleSubmit = async () => {
    if (isRequiredDataFilled()) {
      let isDataSaved = await submitData();

      if (isDataSaved) {
        setToastMessage({
          message: 'Changes saved',
          messageType: Toast_Message_Type.Success
        });
      } else {
        setToastMessage({
          message: 'Server error',
          messageType: Toast_Message_Type.Error
        });
      }
    }
  };

  const handleLinkClick = () => {
    setHelpModalData(helpLinks.helpVideoButton);
  }

  const checkIsDisabled = useCallback(() => {
    if (configSetting.length < 2 && redoConfigSetting.length < 1) {
      return true;
    }
    return false
  }, [configSetting, redoConfigSetting])

  useEffect(() => {
    if (requiredData.showToast) {
      setToastMessage({
        message: requiredData.toastMessage || 'You need to select a product',
        messageType: Toast_Message_Type.Error
      });
      setRequiredData({ ...requiredData, showToast: false });
    }
     
  }, [requiredData.showToast]);

  const isPageLive = useCallback(() => {
    return latestSettings?.status;
    
  }, [latestSettings]);

  return (
    <div className={styles.main_header}>
      <div className={styles.header_left_section}>
        <div className={styles.exit_button_container}>
          <ExitButton />
        </div>
        <div className={`px-3 ${styles.title_text}`}>{latestSettings?.name}</div>

        <div className={`badge-container`}>
          <Badge isActive={!isPageLive()} />
        </div>

        <div>
          <Button className={styles.help_button} onClick={handleLinkClick} color='teal' variant='outline' >{t('help')}</Button>
        </div>
      </div>

      <div className={styles.header_right_section}>

        <LayoutToggle handleChange={handleChange} isMobileView={isMobileView} />

        <div className={styles.undo_redo_btn}>
          <div className={styles.nav_view_button}>
            <Button onClick={undoFun} disabled={configSetting.length <= 1} plain>
              <Icon source={IconSource.Undo} iconAlt={'exit_button'} >
                <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg" className={styles.action_button}>
                  <path d="M12.5 8.5C9.85 8.5 7.45 9.49 5.6 11.1L2 7.5V16.5H11L7.38 12.88C8.77 11.72 10.54 11 12.5 11C16.04 11 19.05 13.31 20.1 16.5L22.47 15.72C21.08 11.53 17.15 8.5 12.5 8.5Z" fill="black" />
                </svg>
              </Icon>
            </Button>
          </div>

          <div className={styles.nav_view_button}>
            <Button onClick={redoFun} disabled={redoConfigSetting.length < 1} plain>
              <Icon source={IconSource.Redo} iconAlt={'exit_button'} >
                <svg width="21" height="10" viewBox="0 0 21 10" fill="none" xmlns="http://www.w3.org/2000/svg" className={styles.action_button}>
                  <path d="M17.4 4.1C15.55 2.49 13.15 1.5 10.5 1.5C5.85004 1.5 1.92004 4.53 0.540039 8.72L2.90004 9.5C3.42672 7.89999 4.44489 6.507 5.80951 5.51945C7.17412 4.5319 8.81557 4.00017 10.5 4C12.45 4 14.23 4.72 15.62 5.88L12 9.5H21V0.5L17.4 4.1Z" fill="black" />
                </svg>
              </Icon>
            </Button>
          </div>
        </div>

        <div className={styles.header_switch_container}>
          <Switch
            isOn={isPageLive()}
            handleToggle={handleToggle}
          />
        </div>

        <div className='px-3'>
          <Button className={!checkIsDisabled() && styles.save_button} onClick={handleSubmit} color='teal' disabled={requiredData.isDisable ? requiredData.isDisable : checkIsDisabled()} primary>
            {t('save')}
          </Button>
        </div>
        <Toast
          message={toastMessage.message}
          messageType={toastMessage.messageType}
          onDismiss={(() => setToastMessage({ message: '', messageType: '' }))}
        />
      </div>
    </div>
  );
}

export default Header;
