import { Button } from '@shopify/polaris';
import { useState, useEffect } from 'react';
import { CDN_BASE_URL } from '../../../config';
import Label from '../Label';
import { t } from 'i18next';

const LayoutSelector = ({ showTooltip, label, onChange, value, tooltipText }) => {
    const [selectedValue, setSelectedValue] = useState(value);

    const layouts = [
        {
            icon: 'layout1.svg',
            value: 1
        },
        {
            icon: 'layout2.svg',
            value: 2
        },
        {
            icon: 'layout3.svg',
            value: 3
        }
    ]

    const handleChange = (newValue) => {
        setSelectedValue(newValue)
        onChange(newValue, value)
    }

    useEffect(() => {
        setSelectedValue(value)
    }, [value])

    return (
        <div className='layout-selector-container'>
            {label && <Label label={t(label)} tooltipText={tooltipText} showTooltip={showTooltip} />}
            {layouts?.map((item) => {
                return (
                    <Button key={item.value} disabled={selectedValue === item.value} onClick={() => handleChange(item.value)}>
                        <img src={CDN_BASE_URL + `assets/${item.icon}`} alt={`layout ${item.value}`} />
                    </Button>)
            }
            )}
        </div>
    );
}

export default LayoutSelector;