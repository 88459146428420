import React, { useContext, useMemo, useState } from 'react';
import { cloneDeep } from 'lodash';
import { t } from 'i18next';
import { PageData } from '../../../../../ContextAPI/Context';
import { updateTargetedValue, getTargetedValue, getTargeted, CreatedNewObj, syncComponentSettings } from '../../../../../Utils/Common';
import { ThreeSectionLayout, ThreeSectionLayoutSettings, WidgetType } from '../../../../../Constant';
import { Modal, Icon } from '../../../../../Shared/Components';
import { IconSource } from '../../../../../Shared/Components/Icon';
import '../../../../../Style/theme-block-section.css'

/*
  Common component
  render group of button which contains visibility, remove and drag handler buttons
*/
const VisibilityButtonGroup = ({ item, widgetId, parentWidgetId, provided, handleMouseEvent, target, isParentVisible = true, onClick }) => {
    const [shouldDeleteModalOpen, setShouldDeleteModalOpen] = useState(false);
    const { configSetting, updateData, isRequiredDataFilled, checkRemovable, latestConfigSetting } = useContext(PageData)
    const targetPath = `${item.target}.${item.condition}`

    const hideHandle = (event) => {
        event.stopPropagation();
        const value = isTargetVisible;
        const cloneSettings = CreatedNewObj(latestConfigSetting);
        let updatedSetting = updateTargetedValue(cloneSettings, targetPath, value, widgetId, parentWidgetId, item.type, item.id);
        if (ThreeSectionLayout.includes(item.type)) {
            const target = ThreeSectionLayoutSettings[item.type].target;
            updatedSetting = updateTargetedValue(cloneSettings, target, value, widgetId, parentWidgetId, item.type, item.id);
        }

        if (item?.syncSettings) {
            updatedSetting = syncComponentSettings(cloneSettings, targetPath, value, parentWidgetId, widgetId, item.type, item.syncSettings);
        }

        updateData(updatedSetting);
    }

    const isTargetVisible = useMemo(() => {
        if (ThreeSectionLayout.includes(item.type)) {
            const target = ThreeSectionLayoutSettings[item.type].target;
            return !getTargetedValue(latestConfigSetting, target, item.id, parentWidgetId);
        }
        if (item?.id !== widgetId && widgetId !== parentWidgetId) {
            // This section for check block and it's updated parents widgets
            const cloneSettings = cloneDeep(latestConfigSetting);
            const selectedWidget = cloneSettings?.widgets.find((widget) => widget.id === parentWidgetId && widget?.widgets?.find((e) => e?.id === widgetId));
            return !!selectedWidget && !getTargetedValue(latestConfigSetting, targetPath, widgetId, parentWidgetId, item.type, item.id);
        } else {
            return !getTargetedValue(latestConfigSetting, targetPath, widgetId, parentWidgetId, item.type, item.id);
        }
    }, [latestConfigSetting, item, widgetId, parentWidgetId]);


    const handleMouseDown = (e) => {
        if (handleMouseEvent) {
            if (isRequiredDataFilled()) {
                handleMouseEvent(e);
            }
        }
    }

    const handleModalToggle = (event) => {
        event?.stopPropagation();
        if (isRequiredDataFilled()) {
            setShouldDeleteModalOpen((active) => !active)
        }
    }

    const handleDelete = (event) => {
        event?.stopPropagation();
        if (isRequiredDataFilled()) {
            const key = item.id ? 'id' : 'type'; // if id property is not exist that means item is block
            const value = item.id ? item.id : item.type; // if id property is not exist that means item is block
            removeElement(key, value, target);
        }
    }

    const removeElement = (key, value, newTarget = target, isRemoved) => {
        let latestSetting = cloneDeep(configSetting[configSetting.length - 1]);
        const blockType = item.id ? '' : item.type; // if id property is not exist that means item is block

        const previewData = getTargeted(latestSetting, newTarget, 1, widgetId, parentWidgetId, blockType, item.id);
        const index = previewData.findIndex((item) => item[key] == value);
        let widgetType = previewData[index].type;
        if (index >= 0) {
            if (widgetType !== WidgetType.MultiProductUpsell && previewData.length === 1 && !isRemoved) {
                newTarget = newTarget.split('.');
                newTarget.splice(newTarget.length - 2, 2);
                removeElement(key, parentWidgetId, newTarget.join('.'), true);
            } else {
                previewData.splice(index, 1);
                updateData(latestSetting);
                onClick();
            }
        }
    };

    const isRemovable = useMemo(() => {
        if (item.isRemovable) {
            // MultiProductUpsell then check if the parent widget is the same as the current widget
            if (parentWidgetId === widgetId && parentWidgetId === item.id) {
                return checkRemovable(item.minCount, item.count);
            }
            // Check Widget is same as the current widget
            else if (widgetId === item.id) {
                return checkRemovable(item.minCount, item.count);
            } else {
                return true;
            }
        }
        return false;
    }, [target, parentWidgetId, widgetId, item.id, item.minCount, item.count, item.isRemovable, checkRemovable]);

    return (
        <>
            <div className='icon-bundle'>
                {item.target && isParentVisible && item.count !== item.minCount && (
                    isTargetVisible ?
                        <span style={!item.isDraggable ? { paddingRight: '4px' } : {}} className='close-eye-icon' onClick={hideHandle}>
                            <Icon source={IconSource.EyeClose} iconAlt={IconSource.EyeClose} className='eye-icon' />
                        </span>
                        :
                        <span className='visible' onClick={hideHandle}>
                            <Icon source={IconSource.EyeOpen} iconAlt={IconSource.EyeOpen} className='eye-icon' />
                        </span>
                )}

                {isRemovable &&
                    <span className='visible grab' onClick={handleModalToggle}>
                        <div className='delete-icon-wrapper'>
                            <Icon source={IconSource.Delete} iconAlt={IconSource.Delete} />
                        </div>
                    </span>
                }

                {item.isDraggable &&
                    <span className='visible grab' {...provided?.dragHandleProps} onMouseDown={handleMouseDown} >
                        <div className='grab-icon-wrapper'>
                            <Icon source={IconSource.DragHandle} iconAlt={IconSource.DragHandle} />
                        </div>
                    </span>
                }

                {shouldDeleteModalOpen &&
                    <Modal
                        active={shouldDeleteModalOpen}
                        onSecondaryAction={handleModalToggle}
                        onPrimaryAction={handleDelete}
                        primaryButtonLabel={t('yes_remove')}
                        secondaryButtonLabel={t('no_keep')}
                        primaryButtonClass='wix-primary-button'
                        title={t('confirm_model_title')}
                        content={`${t('confirm_model_content')} ${item.id ? t('widget') : t('block')}?`} // If id property is not exist that means item is block else it's widget
                    />
                }
            </div>
        </>
    )
}
export default VisibilityButtonGroup;