import React, { useCallback, useState } from 'react'
import { DesktopMajor, MobileMajor } from '@shopify/polaris-icons';
import { ActionList, Button, Icon as PolarisIcon, Popover } from '@shopify/polaris';

import styles from './LayoutToggle.module.css'
import { PLATFORM } from '../../../config';
import { PlatformType } from '../../../Constant';
import Icon from '../Icon';
import { t } from 'i18next';

const LayoutToggle = (props) => {
    const { handleChange, isMobileView } = props;
    const [active, setActive] = useState(false);

    const toggleActive = useCallback(() => setActive((active) => !active), []);

    const activator = (
        <div className={styles.button_container}>
            <Button onClick={toggleActive}>
                <PolarisIcon source={isMobileView ? MobileMajor : DesktopMajor} />
            </Button>
        </div>
    );

    const layout = [
        {
            iconName: 'desktop.svg',
            iconAltText: 'Desktop',
            isMobileView: false,
        },
        {
            iconName: 'mobile.svg',
            iconAltText: 'Mobile',
            isMobileView: true,
        }
    ];

    return (
        <>
            {PLATFORM === PlatformType.Wix ?
                layout.map( (layoutItem, index) => {
                    return (
                        /* Default should desktop button so if isMobileView is undefined or null default desktop button should be selected. */
                        <div key={index} className={`${styles.layout_button} ${!!isMobileView === layoutItem.isMobileView? styles.underline : styles.margin}`} onClick={() => handleChange(layoutItem.isMobileView)}>
                            <Icon iconName={layoutItem.iconName} iconAlt={layoutItem.iconAltText} />
                        </div>
                    )
                })
                :
                <Popover active={active} activator={activator} onClose={toggleActive}>
                    <ActionList
                        actionRole='menuitem'
                        items={[
                            {
                                active: !isMobileView,
                                content: t('desktop'),
                                icon: DesktopMajor,
                                onAction: () => {
                                    handleChange(false);
                                    toggleActive();
                                },
                            },
                            {
                                active: isMobileView,
                                content: t('mobile'),
                                icon: MobileMajor,
                                onAction: () => {
                                    handleChange(true);
                                    toggleActive();
                                },
                            },
                        ]}
                    />
                </Popover>
            }
        </>
    )
}

export default LayoutToggle;