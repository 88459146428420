import { useContext, useEffect, useState } from 'react';
import { PageData } from '../../../../../ContextAPI/Context';
import { cloneDeep } from 'lodash';
import { Ids, getGroupData, getWidgets, getTargetedValue, getUniqueUpsellName, changeLayout } from '../../../../../Utils/Common';
import { WidgetType } from '../../../../../Constant';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import Accordion from '../Accordion';
import MultiProductWidget from '../MultiProductWidget';

/*
Common RenderWidgets where added widget and droppable place shows
*/
const RenderWidgets = ({ item, onClick, id, handleAddSection }) => {
    const { updateData, latestConfigSetting, unifiedEditor, setIsHideLeftBarBanner, isHideLeftBarBanner, requiredData, addWidgetByTarget, setAddWidgetByTarget, isRequiredDataFilled, previewType, isInitWidgetEmpty, setIsInitWidgetEmpty, submitData } = useContext(PageData);
    const [data, setData] = useState([]);
    const [isDragDisabled, setIsDragDisabled] = useState([]);
    const [newAddedWidget, setNewAddedWidget] = useState({})

    useEffect(() => {
        setIsDragDisabled(requiredData.stopChanges);
    }, [requiredData.stopChanges]);

    useEffect(() => {
        if (latestConfigSetting) {
            const configWidgets = getTargetedValue(latestConfigSetting, item.target);
            if (configWidgets) {
                const widgets = getWidgets(unifiedEditor.widgets, configWidgets, item.target, previewType);
                setData(widgets);
            } else {
                setData([]);
            }
        }
    }, [latestConfigSetting, item.target, unifiedEditor.widgets])

    const handleChange = (event) => {
        // This function is used to add new widgets from the footer Add Section button
        if (isRequiredDataFilled()) {
            const targetedWidget = getTargetedValue(unifiedEditor, event);
            const selectedWidgetSchema = getTargetedValue(unifiedEditor, event.replace('defaultSections', 'widgets'));
            let widgets = getTargetedValue(latestConfigSetting, item.target);
            let configNewWidget = cloneDeep(targetedWidget);

            // This function is used to add new widgets if syncSettings is true and widget already exists then added existing settings of widgets
            if (selectedWidgetSchema?.syncSettings) {
                const syncWidgets = cloneDeep(widgets);
                const isExist = syncWidgets.some(widget => widget.type === selectedWidgetSchema.type);
                if (isExist) {
                    configNewWidget = syncWidgets.find(widget => widget.type === selectedWidgetSchema.type);
                }
            }

            configNewWidget.id = Ids();
            configNewWidget.slotId = latestConfigSetting?.slotId;
            configNewWidget.variantId = latestConfigSetting?.id;
            if (configNewWidget?.type) {
                if (configNewWidget.type === WidgetType.MultiProductUpsell) {
                    const widgetArr = isInitWidgetEmpty ? configNewWidget.widgets.filter((widget) => widget.type !== WidgetType.Banner) : configNewWidget.widgets;
                    configNewWidget.widgets = getGroupData(unifiedEditor?.defaultSections, widgetArr);
                } else if (configNewWidget?.blocks?.length > 0) {
                    configNewWidget.blocks = configNewWidget.blocks.map(block => ({ ...block, id: Ids() })) || []
                    const layout = configNewWidget?.upsellInfo?.layout;
                    if (layout && layout !== 1) {
                        configNewWidget = changeLayout(configNewWidget, layout, 1);
                    }
                }

                if (configNewWidget.name && widgets) {
                    configNewWidget.name = getUniqueUpsellName(configNewWidget.name, configNewWidget.type, widgets);
                }

                if (widgets) {
                    widgets.push(configNewWidget);
                }

                setNewAddedWidget(configNewWidget)
                updateData({ ...latestConfigSetting, sectionAdded: true });

                if (isInitWidgetEmpty) {
                    submitData(latestConfigSetting);
                    setIsInitWidgetEmpty(false);
                }

                if (isHideLeftBarBanner) {
                    setIsHideLeftBarBanner(false);
                }

                if (handleAddSection) {
                    handleAddSection();
                }
            }
        }
    }

    useEffect(() => {
        if (addWidgetByTarget) {
            handleChange(addWidgetByTarget);
            setAddWidgetByTarget('');
        }
         
    }, [addWidgetByTarget]);
    
    return (
        <>
            {/*  added widget shows below  */}
            <Droppable droppableId={JSON.stringify({ ...item, id })} >
                {(provided) => (

                    <div id={`${id}`} className='droppable_zone active-sections' {...provided.droppableProps} ref={provided.innerRef} style={{ minHeight: '0px', marginLeft: '0px' }}>

                        {data.map((item, index) => {
                            return <Draggable key={item.id} draggableId={`${item.id}`} index={index} isDragDisabled={isDragDisabled}>

                                {(provided, snapshot) => (
                                    <div className='section-container' ref={provided.innerRef} {...provided.draggableProps}>

                                        {item?.widgets
                                            ?
                                            <MultiProductWidget
                                                index={index}
                                                provided={provided}
                                                isDefaultOpen={newAddedWidget.id === item.id}
                                                data={item}
                                                onClick={onClick}
                                                target={item.target}
                                                isDragging={snapshot.isDragging}
                                                isHideAddSection={!item.addSectionOptions} />
                                            :
                                            (
                                                <Accordion
                                                    index={index}
                                                    provided={provided}
                                                    isDefaultOpen={newAddedWidget.id === item.id}
                                                    items={item}
                                                    onClick={onClick}
                                                    target={item.target}
                                                    isDragging={snapshot.isDragging}
                                                    addBlock={item.blocks}
                                                    parentWidgetId={''}
                                                />
                                            )}
                                    </div>
                                )}
                            </Draggable>
                        })}

                        {provided.placeholder}
                    </div>
                )}
            </Droppable>

        </>
    )
}
export default RenderWidgets;