import { createContext, useCallback, useEffect, useMemo, useState } from 'react';
import { fetchPreviews, fetchStoreInfo, updatePreview, verifyAuth } from '../Service';
import { cloneDeep } from 'lodash';
import { PlatformType, QueryParams, PreviewType, WidgetType, WidgetTypeWiseCategory, WidgetCategoryType, TargetKeys } from '../Constant';
import { useFlags, useLDClient } from 'launchdarkly-react-client-sdk';
import { LAUNCH_DARKLY_ENABLE_BANNER_KEY, LAUNCH_DARKLY_ENABLE_DISCOUNT_KEY, PLATFORM, CE_BUNDLE_URL, IS_LOCAL } from '../config';
import checkout_shopify_editor from '../Mock/checkout_shopify_editor.json';
import UnauthorizedPage from '../Components/UnauthorizedPage';
import checkout_wix_editor from '../Mock/checkout_wix_editor.json';
import post_purchase_shopify_editor from '../Mock/post_purchase_shopify_editor.json';
// import { Crisp } from 'crisp-sdk-web';
import { changeLayout, getTargetedValue } from '../Utils/Common';
import helpUrls from '../Mock/help_urls.json';

export const PageData = createContext();

const editors = {
  [PlatformType.Shopify]: {
    [PreviewType.CHECKOUT]: checkout_shopify_editor,
    [PreviewType.POST_PURCHASE]: post_purchase_shopify_editor
  },
  [PlatformType.Wix]: {
    [PreviewType.CHECKOUT]: checkout_wix_editor
  },
}

const helpJson = {
  [PlatformType.Shopify]: {
    [PreviewType.CHECKOUT]: helpUrls.shopify.checkout,
    [PreviewType.POST_PURCHASE]: helpUrls.shopify.postPurchase,
  },
  [PlatformType.Wix]: {
    [PreviewType.CHECKOUT]: helpUrls.wix.checkout,
  }
}

const bundles = {
  [PreviewType.CHECKOUT]: CE_BUNDLE_URL,
  [PreviewType.POST_PURCHASE]: CE_BUNDLE_URL
}

const JsonData = ({ children, handleLoading }) => {
  // Used only in this context
  const [isAuthorized, setIsAuthorized] = useState(false);
  const ldClient = useLDClient();
  const flags = useFlags();

  // Context states and reducers
  const [configSetting, setConfigSetting] = useState([]);
  const [redoConfigSetting, setRedoConfigSetting] = useState([]);

  const [unifiedEditor, setUnifiedEditor] = useState();
  const [showProgressBar, setShowProgressBar] = useState(false);
  const [selectedSection, setSelectedSection] = useState('');
  const [previewType, setPreviewType] = useState();
  const [systemSetting, setSystemSetting] = useState();
  const [rightBarLinks, setRightBarLinks] = useState([]);
  const [selectedLink, setSelectedLink] = useState({});
  const [helpModalData, setHelpModalData] = useState({});
  const [previewSelectedSections, setPreviewSelectedSection] = useState({ parentId: '', id: '', isBlock: false });
  const [bannerStatus, setBannerStatus] = useState({});
  const [isHideLeftBarBanner, setIsHideLeftBarBanner] = useState(true);
  const [addWidgetByTarget, setAddWidgetByTarget] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [settingType, setSettingType] = useState('');
  const [requiredData, setRequiredData] = useState({ stopChanges: false, showToast: false, showErrorMessage: false, toastMessage: '', isDisable: false });
  const [isRightBarEmpty, setIsRightBarEmpty] = useState(false);
  const [isMobileView, setIsMobileView] = useState(false);

  const [isAppInstalled, setIsAppInstalled] = useState(false);
  const [isAppIntegrated, setIsAppIntegrated] = useState(false);
  const [appInstallationUrl, setAppInstallationUrl] = useState("");
  const [showCheckStatusButton, setIsCheckStatus] = useState(false);
  const [isCheckStatusDescription, setIsCheckStatusDescription] = useState(false);
  const [isInitWidgetEmpty, setIsInitWidgetEmpty] = useState(false);
  const [selectedWidgetType, setSelectedWidgetType] = useState('');
  const [helpLinks, setHelpLinks] = useState({});

  const isDiscountEnabled = flags[LAUNCH_DARKLY_ENABLE_DISCOUNT_KEY] === false ? false : true;
  const isBannerEnable = flags[LAUNCH_DARKLY_ENABLE_BANNER_KEY];

  const updateData = useCallback((res) => {
    res = { ...res, selected: previewSelectedSections };
    setConfigSetting((prev => [...prev, res]))
  }, [previewSelectedSections]);

  const updateCategory = (widget) => {
    if (widget.type === WidgetType.Banner && widget.button.display) {
      widget.category = WidgetCategoryType.clickable;
    } else {
      widget.category = WidgetTypeWiseCategory[widget.type];
    }
  }

  // check missing properties and add them to response
  const addMissingKeys = (resWidget, defaultSection, defaultSections) => {
    for (let key in defaultSection) {
      // eslint-disable-next-line no-prototype-builtins
      if (!resWidget.hasOwnProperty(key)) {
        resWidget[key] = defaultSection[key];
      } else if (key === TargetKeys.Blocks ) {
        resWidget[key].map((block) => {
          const defaultSectionBlock = defaultSection[key].find((section) => section.type === block.type);
          addMissingKeys(block, defaultSectionBlock, defaultSections);
        })
      } else if (key === TargetKeys.Widgets) {
        resWidget[key].map((widget) => {
          const defaultSectionWidget = defaultSections.find((section) => section.type === widget.type);
          addMissingKeys(widget, defaultSectionWidget, defaultSections);
        })
      }  else if (typeof resWidget[key] === TargetKeys.Object) {
        // If the value of the key is an object, recursively check and add missing keys
        addMissingKeys(resWidget[key], defaultSection[key], defaultSections);
      }
    }
    return resWidget;
  }

  const updateResponse = useCallback((response, schema) => {
    let configWidgets = getTargetedValue(response, 'widgets');
    configWidgets.map((widget) => {

      //Added missing properties in widgets from default widgets properties
      const defaultWidget = schema.defaultSections.find((section) => section.type === widget?.type);
      addMissingKeys(widget, defaultWidget, schema.defaultSections);

      // Add category to widget when category is not defined
      if (widget && !widget.category) {
        updateCategory(widget);

        // Setting category for nested widgets
        if (widget.widgets) {
          widget.widgets.forEach((subWidget) => {
            updateCategory(subWidget);
          });
        }
      }

      // convert layout 1 to 2 or 3 for 'POST_PURCHASE' preview type
      if (widget?.blocks?.length > 0) {
        const layout = widget?.upsellInfo?.layout;
        if (layout && layout !== 1) {
          widget = changeLayout(widget, layout, 1);
        }
      }
      return widget;
    });

    updateData(response);
  }, []);

  const latestConfigSetting = useMemo(() => {
    return structuredClone(configSetting[configSetting.length - 1]);
  }, [configSetting]);

  const updateSubmitResponse = useCallback((response, previewType, defaultLayout) => {
    let configWidgets = getTargetedValue(response, 'widgets');
    configWidgets.map((widget) => {
      if (widget.type === WidgetType.Upsell && previewType === PreviewType.POST_PURCHASE) {
        const layout = widget?.upsellInfo?.layout;
        if (layout && layout !== 1) {
          widget = changeLayout(widget, defaultLayout, layout);
        }
      }
      return widget;
    });
    const {...res} = response;
    return res;
  }, []);

  // Check remove button can be hide for last widget, parent widget or block
  const checkRemovable = useCallback((minCount, count) => {
    // Widget must be exist at least once
    if (minCount === 1 && count === 1) {
      return false;
    }

    // const latestSetting = configSetting[configSetting.length - 1];
    // Block not selected means only widget or parent widget selected
    // if (!id) {
    //   // Outer Widget selected
    //   if ((widgetId && !parentWidgetId) || (parentWidgetId && !widgetId)) {
    //     return latestSetting?.widgets?.length !== 1;
    //   }
    //   // Nested Widget selected
    //   else if (parentWidgetId && widgetId) {
    //     // Get Parent widget target string, so removing widget + widget id from current target string
    //     const parentTarget = getTargetString(target, 2, 2);
    //     const targetedWidget = getTargetedValue(latestSetting, parentTarget, widgetId, parentWidgetId);
    //     return targetedWidget?.widgets?.length !== 1;
    //   }
    // }
    return true;
  }, [configSetting]);


  //Set default widgets data (Only for WIX platform)
  useEffect(() => {
    if(PLATFORM === "WIX" && latestConfigSetting && latestConfigSetting.widgets.length > 0 && previewSelectedSections.id === ""){       
      const selectedObj = {parentId: null, id: latestConfigSetting.widgets[0].id}       
        setPreviewSelectedSection(selectedObj)      
        setSelectedSection({ id: selectedObj.id, isOpen: true });    
    }
  }, [latestConfigSetting,PLATFORM]);
  
  useEffect(() => {
    ldClient && ldClient.identify({ key: 'widget 1' });
  }, [ldClient]);

  const undoFun = useCallback(() => {
    if (configSetting.length > 1) {
      setShowProgressBar(true);

      let configSettingClone = cloneDeep(configSetting);
      const undoState = configSettingClone.pop();

      setRedoConfigSetting((config) => [...config, undoState]);
      setConfigSetting(configSettingClone);

      if (configSettingClone.length > 0) {
        const lastConfigSetting = configSettingClone[configSettingClone.length - 1];
        if (lastConfigSetting.widgets.length && lastConfigSetting.selected && lastConfigSetting.selected.id) {
          setSelectedSection({ id: lastConfigSetting.selected.id, isOpen: true });
        }
      }

      if (undoState.sectionAdded) {
        setIsRightBarEmpty(true);
      }
      else {
        setIsRightBarEmpty(false);
      }

      setTimeout(() => {
        setShowProgressBar(false);
      }, 500);
    }
  }, [configSetting]);

  const redoFun = useCallback(() => {
    if (redoConfigSetting.length > 0) {
      setShowProgressBar(true);
      let redoConfigSettingClone = cloneDeep(redoConfigSetting);
      const redoState = redoConfigSettingClone.pop();

      setRedoConfigSetting(redoConfigSettingClone);
      setConfigSetting((config) => [...config, redoState]);

      if (redoConfigSettingClone.length > 0) {
        const lastConfigSetting = redoConfigSettingClone[redoConfigSettingClone.length - 1];
        if (lastConfigSetting.widgets.length && lastConfigSetting.selected && lastConfigSetting.selected.id) {
          setSelectedSection({ id: lastConfigSetting.selected.id, isOpen: true });
        }
      }

      setTimeout(() => {
        setShowProgressBar(false);
      }, 500);
    }
  }, [redoConfigSetting]);

  const submitData = useCallback(async (configParams) => {
    setShowProgressBar(true);
    const data = configParams ? configParams : configSetting[configSetting.length - 1];
    const dataClone = cloneDeep(data);
    const responseData = updateSubmitResponse(dataClone, previewType, 1);

    try {
      await updatePreview(responseData);

      setConfigSetting([data]);
      setRedoConfigSetting([]);

      return true;
    } catch (error) {
      return false;
    } finally {
      setShowProgressBar(false);
    }
  }, [configSetting]);

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (navigator.userAgentData.platform === 'macOS') {
        if (e.metaKey && e.key === 's') {
          e.preventDefault();
          submitData();
        } else if (e.metaKey && e.key === 'y') {
          e.preventDefault();
          redoFun();
        } else if (e.metaKey && e.key === 'z') {
          e.preventDefault();
          undoFun();
        }
      } else if (navigator.userAgentData.platform === 'Windows') {
        if (e.ctrlKey && e.key === 's') {
          e.preventDefault();
          submitData();
        } else if (e.ctrlKey && e.key === 'y') {
          e.preventDefault();
          redoFun();
        } else if (e.ctrlKey && e.key === 'z') {
          e.preventDefault();
          undoFun();
        }
      }
    }

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [undoFun, redoFun, submitData]);

  useEffect(() => {
    getInitialData();
    setTimeout(() => {
      setShowProgressBar(false);
    }, 500);
     
  }, []);

  // const chatbot = (data) => {
  //   const store_client_id = data?.storeClientId ? data.storeClientId : '';
  //   const store_email = data?.email ? data.email : '';
  //   const shop_owner = data?.shopOwner ? data.shopOwner : '';
  //   const shop_name = data?.shopName ? data.shopName : '';
  //   const store_url = data?.storeUrl ? data.storeUrl : '';

  //   Crisp.configure(CRISP_WEBSITE_ID);
  //   Crisp.setTokenId(store_client_id);

  //   Crisp.user.setEmail(store_email);
  //   Crisp.user.setNickname(shop_owner);
  //   Crisp.user.setCompany(shop_name, {
  //     url: store_url
  //   });

  //   Crisp.session.setData({
  //     storeName: shop_name,
  //     storeUrl: store_url
  //   });

  //   Crisp.chat.show();
  // }

  const setDataForEditor = (queryParams) => {
    //chatbot setup using crisp library
    // chatbot(data);

    // Set editor json by type
    const schema = editors[PLATFORM][queryParams.preview];
    const helplinkJson = helpJson[PLATFORM][queryParams.preview];
    setHelpLinks(helplinkJson);
    setUnifiedEditor(schema);

    //add script for preview  
    const previewSrc = bundles[queryParams.preview];
    const script = document.createElement('script');
    script.src = previewSrc+'?v=20241901-01';
    script.defer = 'defer';
    document.body.appendChild(script);

    const isWix = PLATFORM === PlatformType.Wix;

    fetchPreviews(queryParams, isWix,
      response => {
        updateResponse(response?.data, schema);
        addDefaultWidget(response?.data, queryParams.preview, queryParams.isNewVariant, schema)
        setIsLoading(false);
      },
      err => {
        console.error(err);
        setIsLoading(false);
      });
    handleLoading(false);
  }

  /**
   * To add default multi upsell widget for checkout
   * @param {*} previewType : type of preview
   * @param {*} response: Preview data 
   * @param {*} isNewVariant : add default widget or not
   * @param {*} schema : json schema to add default widget
   */
  const addDefaultWidget = (response, previewType, isNewVariant, schema) => {

    if (isNewVariant === 'true' && response) {
      if (PLATFORM === PlatformType.Wix && response.masterWidgets?.length > 0) {
        const masterWidgets = response.masterWidgets[0].widgets;
        const editorSchema = cloneDeep(schema);
        const defaultWidgets = editorSchema.defaultSections;

        masterWidgets.forEach((widget) => {
          const index = defaultWidgets.findIndex((defaultWidget) => defaultWidget.type === widget.type);
          if (index > -1) {
            defaultWidgets[index] = widget
          }
        });

        editorSchema.defaultSections = defaultWidgets;
        setUnifiedEditor(editorSchema);
      }

      if (response.widgets?.length === 0) {
        const defaultWidgetType = previewType === PreviewType.POST_PURCHASE ? WidgetType.Upsell : WidgetType.MultiProductUpsell;
        const addSectionOptions = schema.addSectionOptions;
        const index = addSectionOptions.findIndex((item) => item.value.includes(defaultWidgetType))
        setIsInitWidgetEmpty(true);
        setAddWidgetByTarget(addSectionOptions[index].value);
      }
    }
  }

  const getInitialData = async () => {
    const params = window.location.search;
    const token = new URLSearchParams(params).get(QueryParams.Token);
    const variantId = new URLSearchParams(params).get(QueryParams.VariantId);
    const preview = new URLSearchParams(params).get(QueryParams.Type) || 'CHECKOUT';
    const platformStoreId = new URLSearchParams(params).get(QueryParams.PlatformStoreId);
    const isNewVariant = new URLSearchParams(params).get(QueryParams.IsNewVariant);

    const queryParams = {
      token,
      variantId,
      preview: PreviewType[preview],
      platformStoreId,
      isNewVariant
    }

    if(platformStoreId) sessionStorage.setItem('platformStoreId', platformStoreId)

    if (IS_LOCAL) {
      verifyAuth(queryParams, token,
        res => {
          setPreviewType(PreviewType[preview]);
          setSystemSetting(res.data);
          setIsAuthorized(true);
          setDataForEditor(queryParams, res);
        },
        err => {
          setIsAuthorized(false);
          handleLoading(false);
          console.log(err);
        }
      );
    } else {
      await fetchStoreInfo()
      .then(res => {
        setPreviewType(PreviewType[preview]);
        setSystemSetting(res.data);
        setIsAuthorized(true);
        setDataForEditor(queryParams, res.data);
      })
      .catch(() => {
        setIsAuthorized(false);
        handleLoading(false);
      })
    }

  };

  const isRequiredDataFilled = useCallback(() => {
    if (requiredData.stopChanges) {
      if (!requiredData.showErrorMessage) {
        setRequiredData({ ...requiredData, showToast: true, showErrorMessage: true });
      } else {
        setRequiredData({ ...requiredData, showToast: true });
      }
      return false;
    } else {
      return true;
    }
  }, [requiredData]);

  return (
    <>
      <PageData.Provider
        value={{
          helpLinks,
          selectedWidgetType, 
          setSelectedWidgetType,
          latestConfigSetting,
          checkRemovable,
          isInitWidgetEmpty,
          setIsInitWidgetEmpty,
          updateData,
          setIsCheckStatusDescription,
          isCheckStatusDescription,
          setIsCheckStatus,
          showCheckStatusButton,
          setIsAppInstalled,
          isAppInstalled,
          isAppIntegrated,
          setIsAppIntegrated,
          appInstallationUrl,
          setAppInstallationUrl,
          setIsMobileView,
          isMobileView,
          settingType,
          setSettingType,
          isLoading,
          configSetting,
          setUnifiedEditor,
          unifiedEditor,
          redoFun,
          undoFun,
          redoConfigSetting,
          showProgressBar,
          submitData,
          selectedSection,
          setSelectedSection,
          rightBarLinks,
          setRightBarLinks,
          systemSetting,
          selectedLink,
          setSelectedLink,
          previewSelectedSections,
          setPreviewSelectedSection,
          isRightBarEmpty,
          setIsRightBarEmpty,
          helpModalData,
          setHelpModalData,
          bannerStatus,
          setBannerStatus,
          previewType,
          isHideLeftBarBanner,
          setIsHideLeftBarBanner,
          isDiscountEnabled,
          isBannerEnable,
          addWidgetByTarget,
          setAddWidgetByTarget,
          requiredData,
          setRequiredData,
          isRequiredDataFilled
        }}
      >
        {!isAuthorized ? <UnauthorizedPage /> : unifiedEditor === undefined ? null : children}
      </PageData.Provider>
    </>
  );
};
export default JsonData;
