import React, { useState, useContext, useEffect, useRef, useMemo } from 'react';
import { t } from 'i18next';
import { PageData } from '../../../../../ContextAPI/Context';
import { Ids, getTargetString, getTargetedValue, makeRightBarLinks, updateTargetedValue } from '../../../../../Utils/Common';
import VisibilityButtonGroup from '../VisibilityButtonGroup';
import Icon, { IconSource } from '../../../../../Shared/Components/Icon';
import { componentModel } from '../../../Models/Component.model';
import { PLATFORM } from '../../../../../config';
import { BlockLocation, ColumnLayout, PlatformType, ThreeSectionLayout } from '../../../../../Constant';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import Accordion from '../Accordion';
import AddWidget from '../AddWidget';
import '../../../../../Style/theme-block-section.css';

/*
Rendering multi level widget which contains widgets
*/
const MultiProductWidget = ({ data, onClick, isHideAddSection, target, index, provided, isDragging, isDefaultOpen }) => {

    const [isOpen, setOpen] = useState(false);
    const [isHide, setIsHide] = useState(false);
    const [isDragDisabled, setIsDragDisabled] = useState([]);
    const [widgetLayout, setWidgetLayout] = useState();
    const { setSelectedSection, selectedSection, setRightBarLinks, selectedLink, setSelectedLink, setPreviewSelectedSection, isRequiredDataFilled, previewSelectedSections,
        updateData, requiredData, latestConfigSetting} = useContext(PageData);
    const divRef = useRef();

    useEffect(() => {
        if (selectedLink?.id === data?.id) {
            divRef.current.click();
            setSelectedLink({});
        } else if (selectedLink?.parentSectionID === data?.id) {
            setSelectedLink({});
            setOpen(true);
        }
    }, [selectedLink])

    useEffect(() => {
        if (isDefaultOpen && divRef.current) {
            divRef.current.click();
        }
    }, [isDefaultOpen]);

    useEffect(() => {
        if (isDefaultOpen && !selectedSection?.id) {
            setOpen(true);
        }
    }, [isDefaultOpen]);

    useEffect(() => {
        if (selectedSection?.id === data?.id) {
            setOpen(selectedSection.isOpen);
            divRef.current.click();
            setIsHide(false);
            setSelectedSection();
        }
    }, [data]);

    useEffect(() => {
        setIsDragDisabled(requiredData.stopChanges);
    }, [requiredData.stopChanges]);

    useEffect(() => {
        const widget = getTargetedValue(latestConfigSetting, data?.target, '', data?.id);
        setWidgetLayout(widget?.upsellInfo?.layout);
    }, [data, latestConfigSetting]);

    const handleClick = (e) => {        
        if (isRequiredDataFilled()) {
            Array.from(document.querySelectorAll('.selected-item-active')).forEach((el) => el.classList.remove('selected-item-active'));

            if (selectedSection?.id !== data?.id && previewSelectedSections.id === data.id && !previewSelectedSections?.isBlock) {
                setPreviewSelectedSection({ id: null, parentId: null });
                onClick();
            } else {
                const modelData = {
                    parentWidgetId: data.id,
                    componentType: data.type,
                    count: data.count,
                    minCount: data.minCount,
                    target: target
                }
                onClick(e, componentModel(modelData));

                e.target.closest('.accordion-title-outer').classList.add('selected-item-active');
                const widgets = data?.widgets?.length > 0 ? data?.widgets : [];
                let links = makeRightBarLinks(widgets, data.id);

                setPreviewSelectedSection({ id: data.id, parentId: null });
                setRightBarLinks(links);

                if (isHide) {
                    setIsHide(false);
                } else {
                    if (selectedSection?.id) {
                        setOpen(selectedSection.isOpen);
                    } else {
                        setOpen(true);
                    }
                }
            }
        }
    }

    const handleMouseEvent = (e) => {
        e.stopPropagation();
        setIsHide(true);
        setSelectedSection({ id: data?.id, isOpen: isOpen });
    }

    // get layout left right or bottom
    const getLayout = (id, widget) => {
        return widget.find(item => item.id === id);
    }

    const handleDragEnd = (result, Widget) => {
        if (!result.destination) return;
        // Check if layout left right or bottom exit or not
        const layoutsExist = ThreeSectionLayout.some(layout => Widget.some(item => Object.values(item).includes(layout)));
        let latestSetting = latestConfigSetting;
        if (layoutsExist) {
            const source = getLayout(result.source.droppableId, Widget);
            const destination = getLayout(result.destination.droppableId, Widget);
            let sourceBlocks = getTargetedValue(latestSetting, `${source.target}.blocks`, source.id, data?.id);
            let destinationBlocks = getTargetedValue(latestSetting, `${destination.target}.blocks`, destination.id, data?.id);
            // remove selectedBlock from source blocks and add it to destination blocks
            const selectedBlock = sourceBlocks.splice(result.source.index, 1)?.map(e => ({ ...e, location: BlockLocation[destination?.type]}));
            let updatedSetting = updateTargetedValue(latestSetting, `${source.target}.blocks`, sourceBlocks, source.id, data?.id);
            destinationBlocks.splice(result.destination.index, 0, ...selectedBlock);
            updatedSetting = updateTargetedValue(latestSetting, `${destination.target}.blocks`, destinationBlocks, destination.id, data?.id);
            // When dragging a block from one column (left, right, or bottom) to another column (left, right, or bottom), set the destinationWidgetId to check conditions with the current column.
            updatedSetting = {...updatedSetting, destinationWidgetId : destination.id };
            updateData(updatedSetting);
        } else {
            let target = Widget[0].target;
            target = getTargetString(target, 1, 1);
            let targetedValue = getTargetedValue(latestSetting, target, '', data?.id);
            const selectedBlock = targetedValue.splice(result.source.index, 1)[0];
            targetedValue.splice(result.destination.index, 0, selectedBlock);
            updateData(latestSetting);
        }
    }
    const isParentVisible = useMemo(() => {
        return getTargetedValue(latestConfigSetting, `${data.target}.${data.condition}`, '', data?.id);
    }, [latestConfigSetting, data]);

    return (
        <ul key={index} style={{ backgroundColor: isDragging ? 'white' : 'none', boxShadow: isDragging ? '2px 2px 5px lightgrey' : 'none' }}>
            <li className={data?.widgets?.length > 0 ? `submenu-outer` : null}>
                <div
                    ref={divRef}
                    className={`accordion-title-outer`}
                    onClick={e => { handleClick(e) }}
                >
                    <div className='accordion-title-container' >
                        {
                            data?.widgets?.length ? (
                                <div onClick={(e) => { e.stopPropagation(); setOpen(!isOpen) }} className={`accordion-dropdown-icon ${!isHide || isOpen ? 'open' : ''}`}>
                                    <span className={!isHide && isOpen ? 'accordion-dropdown-icon-open' : 'accordion-dropdown-icon-close'}>
                                        <Icon source={IconSource.Caret} iconAlt={IconSource.Caret} />
                                    </span>
                                </div>
                            ) : null
                        }

                        <span
                            className={`accordion-title-span accordion-title ${data?.widgets?.length > 0 ? 'top-section-remove-padding' : 'top-section-padding'}`}
                        >
                            <Icon iconName={data.icon} iconAlt={data.blockName} className={PLATFORM === PlatformType.Shopify ? 'shopify_icon' : ''} />
                            {t(data.sectionName)}
                        </span>
                    </div>
                    {<VisibilityButtonGroup handleMouseEvent={handleMouseEvent} provided={provided} item={data} widgetId={data.id} parentWidgetId={data.id} target={target} onClick={onClick}/>}
                </div>

                <div className={`${!isOpen || isHide ? 'collapsed none' : 'section-list'}`} >
                    <DragDropContext onDragEnd={(e) => handleDragEnd(e, data?.widgets)} >
                        {
                            (widgetLayout && ColumnLayout.includes(widgetLayout)) ?
                                data?.widgets && data?.widgets?.length > 0 && data?.widgets?.map((item, index) => {
                                    return (
                                        <Accordion
                                            key={index}
                                            items={item}
                                            index={index}
                                            provided={provided}
                                            onClick={onClick}
                                            addBlock={item.blocks}
                                            parentWidgetId={data.id}
                                            target={item.target}
                                            isDefaultOpen={isDefaultOpen}
                                            isParentOpen={isOpen}
                                            isParentVisible={isParentVisible}
                                        />
                                    )
                                })
                                : (
                                    <Droppable droppableId={`${Ids()}`}>
                                        {(provided) => (
                                            <div ref={provided.innerRef}  >
                                                {data?.widgets && data?.widgets?.length > 0 && data?.widgets?.map((item, index) => {
                                                    return (
                                                        <Draggable
                                                            key={`${item.id}`}
                                                            draggableId={`${item.id}`}
                                                            index={index}
                                                            isDragDisabled={isDragDisabled}
                                                        >
                                                            {(provided) => (
                                                                <div
                                                                    ref={provided.innerRef}
                                                                    {...provided.draggableProps}
                                                                >
                                                                    <Accordion
                                                                        key={index}
                                                                        items={item}
                                                                        index={index}
                                                                        provided={provided}
                                                                        onClick={onClick}
                                                                        addBlock={item.blocks}
                                                                        parentWidgetId={data.id}
                                                                        target={item.target}
                                                                        isDefaultOpen={isDefaultOpen}
                                                                        isParentOpen={isOpen}
                                                                        isParentVisible={isParentVisible}
                                                                    />
                                                                </div>
                                                            )}
                                                        </Draggable>

                                                    );
                                                })
                                                }
                                                {provided.placeholder}
                                            </div>
                                        )
                                        }
                                    </Droppable>
                                )
                        }
                    </DragDropContext>
                    {data?.widgets?.length > 0 && !isHideAddSection && <AddWidget defaultOptions={data?.addSectionOptions} widgets={data?.widgets} target={target} parentWidgetId={data?.id} />}
                </div>

            </li>
        </ul>

    )
}
export default MultiProductWidget;