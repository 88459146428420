import React from 'react'
import { Badge as PolarisBadge } from '@shopify/polaris'
import { Badge as ChakraBadge } from '@chakra-ui/react';
import styles from './Badge.module.css';
import { PLATFORM } from '../../../config';
import { PlatformType } from '../../../Constant';
import { t } from 'i18next';

const Badge = ({ isActive }) => {
    return (
        <>
            {PLATFORM === PlatformType.Wix ?
                <ChakraBadge
                    className={!isActive ? styles.badge_complete : styles.badge_incomplete}
                >
                    {isActive ? t('draft') : t('live')}
                </ChakraBadge>
                :
                <PolarisBadge
                    status={`${isActive ? 'attention' : 'success'}`}
                    progress={`${isActive ? 'incomplete' : 'complete'}`}
                >
                    {isActive ? t('draft') : t('live')}
                </PolarisBadge>
            }
        </>
    )
}

export default Badge