export const componentModel = (data) => {
    const {
        id = null,
        widgetId = null,
        parentWidgetId = null,
        componentType = '',
        count = null,
        minCount = null,
        blockType = '',
        target = '',
    } = data;

    return {
        id,
        widgetId,
        parentWidgetId,
        componentType,
        count,
        minCount,
        blockType,
        target
    }
}
