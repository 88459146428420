import { useContext, useEffect, useState } from 'react';
import { cloneDeep } from 'lodash';
import { t } from 'i18next';

import { PageData } from '../../../../../ContextAPI/Context';
import { Ids, getOptions, getTargetedValue, getUniqueUpsellName } from '../../../../../Utils/Common';
import { CDN_BASE_URL } from '../../../../../config';
import { IconSource } from '../../../../../Shared/Components/Icon';
import { Popover } from '../../../../../Shared/Components';

/* Add widget button to add widget in top level widgets */
const AddWidget = ({
  widgets,
  defaultOptions,
  parentWidgetId,
  target
}) => {

  const [options, setOptions] = useState([]);
  const {
    updateData,
    configSetting,
    unifiedEditor,
    isRequiredDataFilled
  } = useContext(PageData);

  useEffect(() => {
    if (defaultOptions) {
      let listOptions = getOptions(defaultOptions, unifiedEditor.widgets);
      setOptions(
        listOptions.map((item) => {
          return { label: item.sectionName, value: item.type, icon: item.icon };
        })
      );
    }
  }, [defaultOptions, widgets, unifiedEditor.widgets]);

  const handleChange = (e) => {
    if (isRequiredDataFilled()) {
      let latestSetting = cloneDeep(configSetting[configSetting.length - 1]);
      let selectedWidget = e;
      let subTarget = `.widgets`;
      let customTarget = `defaultSections.[type:${selectedWidget}]`;

      let targetedValue = getTargetedValue(unifiedEditor, customTarget);

      if(targetedValue?.blocks) {
        targetedValue.blocks = targetedValue?.blocks?.map(e => ({ ...e, id: Ids() })) 
      }

      let add_widget_object = getTargetedValue(latestSetting, target + subTarget, '', parentWidgetId);

      let addWidget = cloneDeep(targetedValue);
      addWidget.id = Ids();
      if (addWidget.name && add_widget_object) {
        addWidget.name = getUniqueUpsellName(addWidget.name, addWidget.type, add_widget_object);
      }
      addWidget.isVisible = true;
      add_widget_object.push(addWidget);

      updateData( {...latestSetting, sectionAdded: true } );
    }
  };

  return (
    <ul>
      {options.length > 0 ? (
        <li className={`add-section-wrap sub-menu collapsed`}>
          <Popover 
              options={options || []}
              onClick={handleChange}
              iconSource={IconSource.Plus}
              title={t('add_widget')}
              titleClassName={'add-section-title-link'}
            />
        </li>
      ) : (
        <div className={`disabled-add-button`}>
           <img src={CDN_BASE_URL + `assets/plus-disabled.svg`} alt={`Add widget`} />
          {t('add_widget')} 
        </div>
      )}
    </ul>
  );
};
export default AddWidget;
