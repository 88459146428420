import React from 'react'
import { Menu, MenuButton, MenuGroup, MenuItem, MenuList } from '@chakra-ui/react';
import Icon from '../Icon';
import styles from './PopoverWix.module.css';
import { t } from 'i18next';
import '../../../Style/customWix.css';

const PopoverWix = ({ title, options, onClick, iconSource, titleClassName, children }) => {

    return (
        <div className={'wix-popover'}>
            <Menu autoSelect={false} >
                <MenuButton className={`${styles.dropdown_button} ${titleClassName ? titleClassName : ''}`}>
                    <div className={`${styles.dropdown_wrap} block-spacing`}>
                        {children ?
                            children :
                            <Icon source={iconSource} iconAlt={iconSource} />
                        }
                        <div className={styles.dropdown_title}>{title}</div>
                    </div>
                </MenuButton>
                <MenuList className={styles.menulist_wrap}>
                    <MenuGroup className={styles.options_title} title={title.toLocaleUpperCase()}>
                        {options?.map((item) => {
                            return (
                                <MenuItem key={item.value} value={item.value} onClick={() => onClick(item.value)} >
                                    <div className={styles.dropdown_options_wrap}>
                                        <img className={styles.option_icon} src={`assets/wix/images/${item.icon}`} alt={`plus icon`} />
                                        <div className={styles.option_label}>{t(item.label)}</div>
                                    </div>
                                </MenuItem>
                            )
                        })}
                    </MenuGroup>
                </MenuList>
            </Menu>
        </div>
    )
}

export default PopoverWix;