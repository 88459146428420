import { useCallback, useState } from 'react';
import { TextField } from '@shopify/polaris';
import { InputGroup, InputRightElement, NumberDecrementStepper, NumberIncrementStepper, NumberInput as CharkraNumberInput, NumberInputField, NumberInputStepper } from '@chakra-ui/react';

import styles from './NumberInput.module.css';
import Label from '../Label';
import { PLATFORM } from '../../../config';
import { PlatformType } from '../../../Constant';

const NumberInput = (props) => {
    const { tooltipText, showTooltip, label, onChange, min, max, suffix, placeholder, value, minLimitWaringMessage, maxLimitWaringMessage } = props;
    const [warningMessage, setWarningMessage] = useState('');
    let timeoutId;

    const clearWarningMessage = () => {
        setWarningMessage('');
    };

    const handleChange = useCallback((e) => {
        let val = e === "" ? null : Number(e);
        let showErrorMessage = false;

        if (max && val && val > max) {
            val = max;
        } else if (val && val < min) {
            val = min;
        }

        // "value" having the previous value and "val" having current value when user incise the value if val is grater then max value user shows an error message.
        if (val >= max && val === value) {
            showErrorMessage = true;
            maxLimitWaringMessage && setWarningMessage(maxLimitWaringMessage);
        }
        // "value" having the previous value and "val" having current value when user decrease the value if val is less then min value user shows an error message.
        else if (val <= min && val === value) {
            showErrorMessage = true;
            minLimitWaringMessage && setWarningMessage(minLimitWaringMessage);
        } else {
            setWarningMessage('');
        }

        if(showErrorMessage) {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
        
            timeoutId = setTimeout(clearWarningMessage, 5000);
        }

        onChange(val);
    }, [onChange]);

    // useEffect(() => {
    //     handleChange(value);
    // }, [max]);

    return (
        <div className={styles.input_wrap}>
            {label && <Label label={label} tooltipText={tooltipText} showTooltip={showTooltip} />}

            {PLATFORM === PlatformType.Wix ?
                <CharkraNumberInput onChange={(valueString) => { if (Number(valueString) >= Number(min) && Number(valueString) <= Number(max)) { handleChange(Number(valueString)) } }} value={value} min={min} max={max}>
                    <InputGroup>
                        <NumberInputField />
                        <InputRightElement className={styles.right_element}>
                            {suffix}
                        </InputRightElement>
                        <NumberInputStepper>
                            <NumberIncrementStepper />
                            <NumberDecrementStepper />
                        </NumberInputStepper>
                    </InputGroup>
                </CharkraNumberInput>
                :
                <TextField
                    type={'number'}
                    suffix={suffix}
                    value={value}
                    onChange={handleChange}
                    min={min}
                    max={max}
                    error={warningMessage}
                    placeholder={placeholder}
                />
            }
        </div>
    );
}

export default NumberInput;