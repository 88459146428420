import React, { useState, useContext, useEffect, useMemo } from 'react';
import { DragDropContext } from 'react-beautiful-dnd';
import { cloneDeep } from 'lodash';
import { t } from 'i18next';
import { PageData } from '../../../ContextAPI/Context';
import { getTargetedValue } from '../../../Utils/Common';
import { ConditionalKeys } from '../../../Constant';
import { Skeleton, Popover } from '../../../Shared/Components';
import { IconSource } from '../../../Shared/Components/Icon';
import RenderWidgets from './Components/RenderWidgets';
import '../../../Style/theme-block-section.css';

const LeftSideBar = props => {
    const { unifiedEditor, configSetting, updateData, setAddWidgetByTarget, isBannerEnable, isRequiredDataFilled, isLoading } = useContext(PageData);
    const [isDraggingItem, setIsDraggingItem] = useState(false);
    const { addSectionOptions } = unifiedEditor;
    const [pageValues, setPageValues] = useState();

    useEffect(() => {
        setPageValues(unifiedEditor.pages[0].value);
    }, [unifiedEditor, configSetting]);

    const handleBeforeCapture = () => {
        setIsDraggingItem(true);

        const droppableList = document.querySelectorAll('.droppable_zone');
        droppableList.forEach(droppable => {
            droppable.style.border = '1px dashed lightgrey';
            droppable.style.borderRadius = '0.25rem';
            droppable.style.minHeight = '10px';
            droppable.style.marginBottom = '5px';
        });
    }

    const handleDragUpdate = (update) => {
        const { destination } = update;

        const droppableList = document.querySelectorAll('.droppable_zone');
        droppableList.forEach(droppable => {
            droppable.style.border = '1px dashed lightgrey';
            droppable.style.backgroundColor = 'white';
        });

        if (!destination) {
            return;
        }

        const droppable = document.getElementById(JSON.parse(destination.droppableId).id);
        droppable.style.border = '1px dashed rgba(69, 143, 255, 1)';
        droppable.style.backgroundColor = 'rgba(237, 244, 254, 1)';
    }

    const handleDragEnd = (result) => {
        setIsDraggingItem(false);
        const droppableList = document.querySelectorAll('.droppable_zone');
        droppableList.forEach(droppable => {
            droppable.style.border = '0';
            droppable.style.borderRadius = '0';
            droppable.style.minHeight = '0px';
            droppable.style.marginBottom = '0px'
            droppable.style.backgroundColor = 'white';
        });

        if (!result.destination) return;
        const dropIndex = result.destination.index;
        const sourceIndex = result.source.index;
        result.destination = JSON.parse(result.destination.droppableId);
        result.source = JSON.parse(result.source.droppableId);
        let target = result.source.target;

        const latestData = cloneDeep(configSetting[configSetting.length - 1]);

        let targetedValue = getTargetedValue(latestData, target);
        let sourceValue = { ...targetedValue[sourceIndex] };
        targetedValue.splice(sourceIndex, 1);

        let updatedTargetedValue = getTargetedValue(latestData, target);

        if (updatedTargetedValue) {
            updatedTargetedValue.splice(dropIndex, 0, sourceValue);
        }
        updateData(latestData);
    }

    const handleAddSection = (v) => {
        if (isRequiredDataFilled()) {
            const data = unifiedEditor.addSectionOptions;
            const index = data.findIndex((item) => item.value === v)
            if (index >= 0) {
                let selectedOption = data[index];
                setAddWidgetByTarget(selectedOption.value);
            }
        }
    }

    const sectionOptions = useMemo(() => {
        if (addSectionOptions) {
            if (!isBannerEnable) {
                return addSectionOptions.filter((option) => option.label !== ConditionalKeys.BannerLabel);
            }
            return addSectionOptions;
        }
        return [];
    }, [addSectionOptions, isBannerEnable]);

    return (
        <div className='left-section ce-leftbar'>
            <div className='content_sidebar'>
                <div className='sidebar-menu-box '>
                    {isLoading ?
                        <Skeleton />
                        :
                        <DragDropContext onDragEnd={handleDragEnd} onDragUpdate={handleDragUpdate} onBeforeCapture={handleBeforeCapture}>
                            {
                                pageValues && pageValues.length > 0 && (
                                    pageValues?.map((pageValue, index) => {
                                        return (
                                            <ul key={index}>
                                                <li>
                                                    {pageValue?.isShowButton &&
                                                        <RenderWidgets item={pageValue.addSectionButton} onClick={props.onClick} id={pageValue.id} isDraggingItem={isDraggingItem} />
                                                    }
                                                </li>
                                            </ul>
                                        );
                                    })
                                )
                            }
                        </DragDropContext>
                    }
                </div>
            </div>
            {!isLoading && (
                <div className='leftbar-add-section'>
                    <Popover
                        options={sectionOptions}
                        onClick={handleAddSection}
                        iconSource={IconSource.CirclePlus}
                        title={t('add_widget')}
                        titleClassName={'add-section-title'}
                    >
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd" d="M1.875 10C1.875 5.5142 5.5142 1.875 10 1.875C14.4858 1.875 18.125 5.5142 18.125 10C18.125 14.4858 14.4858 18.125 10 18.125C5.5142 18.125 1.875 14.4858 1.875 10ZM10 3.125C6.20455 3.125 3.125 6.20455 3.125 10C3.125 13.7954 6.20455 16.875 10 16.875C13.7954 16.875 16.875 13.7954 16.875 10C16.875 6.20455 13.7954 3.125 10 3.125Z" fill="black" />
                            <path fillRule="evenodd" clipRule="evenodd" d="M10 6.25C10.3452 6.25 10.625 6.52982 10.625 6.875V9.375H13.125C13.4702 9.375 13.75 9.65482 13.75 10C13.75 10.3452 13.4702 10.625 13.125 10.625H10.625V13.125C10.625 13.4702 10.3452 13.75 10 13.75C9.65482 13.75 9.375 13.4702 9.375 13.125V10.625H6.875C6.52982 10.625 6.25 10.3452 6.25 10C6.25 9.65482 6.52982 9.375 6.875 9.375H9.375V6.875C9.375 6.52982 9.65482 6.25 10 6.25Z" fill="black" />
                        </svg>
                    </Popover>
                </div>
            )}
        </div >
    );
}

export default LeftSideBar;
