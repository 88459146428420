import React, { useState, useCallback, useRef, useContext } from 'react';
import { t } from 'i18next';
import { PageData } from '../../../../ContextAPI/Context';
import { Icon, Modal } from '../../../../Shared/Components';
import { IconSource } from '../../../../Shared/Components/Icon';
import styles from './ExitButton.module.css';

const ExitButton = () => {
  const [active, setActive] = useState(false);
  const { configSetting } = useContext(PageData);
  const activator = useRef(null);

  const handleExit = useCallback(() => {
    setActive(false);

    if (window.parent) {
      window.parent.postMessage({
        eventType: "exit",
        data: true
      }, "*");
    }
  }, []);

  const handleOpen = useCallback(() => configSetting.length >= 2 ? setActive(true) : handleExit(), [configSetting, handleExit]);

  const handleClose = useCallback(() => {
    setActive(false);
  }, []);

  return (
    <>
      <div className={styles.exit_button} ref={activator} onClick={handleOpen}>
        <Icon source={IconSource.Exit} iconAlt={'exit_button'} />
        <span className={styles.exit_span}></span>
      </div>
      <Modal
        rootStyles={{ height: '500px' }}
        active={active}
        onSecondaryAction={handleClose}
        onPrimaryAction={handleExit}
        title={t('exit_button_title')}
        activator={activator}
        primaryButtonLabel={t('exit_without_saving')}
        secondaryButtonLabel={t('cancel')}
        content={t('exit_button_content')}
      />
    </>
  );
};

export default ExitButton;
