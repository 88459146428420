import React, { useContext, useEffect } from 'react';
import { Select as PolarisSelect } from '@shopify/polaris';
import { Select as ChakraSelect } from '@chakra-ui/react';

import styles from './DropDown.module.css';
import Label from '../Label';


import { t } from 'i18next';
import { PageData } from '../../../ContextAPI/Context';
import { PLATFORM } from '../../../config';
import { PlatformType } from '../../../Constant';

const Dropdown = (props) => {
    const { showTooltip, label, tooltipText, helpText, options, onChange, value } = props;
    const { setHelpModalData, helpLinks } = useContext(PageData);

    useEffect(() => {
        const elements = document.getElementsByName('HELP_TEXT');

        for (const element of elements) {
            element.addEventListener('click', handleHelp);
        }
    
        return () => {
            for (const element of elements) {
                element.removeEventListener('click', handleHelp);
            }
        };
    }, []);

    const handleHelp = (event) => {
        const helpKey = event.target.getAttribute('data-help-key');
        setHelpModalData(helpLinks[helpKey]);
    };

    const translatedOptions = options?.map(item => {
        return {...item, label: t(item.label) }
    });

    return (
        <div className={styles.input_wrap}>

            {label && <Label label={t(label)} tooltipText={tooltipText} showTooltip={showTooltip} />}

            {PLATFORM === PlatformType.Wix ?
                <ChakraSelect onChange={(e) => onChange(e.target.value)} value={value} className={styles.select_wrap}>
                    {translatedOptions?.map((item) => {
                        return <option key={item.value} value={item.value}>{item.label}</option>
                    })}
                </ChakraSelect>
                :
                <PolarisSelect
                    options={translatedOptions}
                    onChange={onChange}
                    value={value}
                />
            }

            {helpText && <div className={styles.help_text} dangerouslySetInnerHTML={{ __html: t(helpText) }} />}
        </div>
    )
}

export default Dropdown;