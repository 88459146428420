import React, { useContext, useEffect, useMemo, useState } from 'react';
import { isArray } from 'lodash';
import { t } from 'i18next';
import { CreatedNewObj, canUpdateField, checkTargetConditions, getConditionalOption, getTargetedValue, syncComponentSettings, updateTargetedValue } from '../../../../../../Utils/Common';
import { PageData } from '../../../../../../ContextAPI/Context';
import { ConditionalKeys, ComponentType, ProductDisplayTypes, UpsellBlocks, ThirdPartyApps, WidgetType, WidgetCategoryType } from '../../../../../../Constant';
import ProductSelector from '../../ProductUpsell/Components/ProductSelector';
import { Slider, FieldGroup, RadioButtonGroup, NumberInput, Label, Text, Button, TextInput, Dropdown, TagInput, CheckBox, TextEditor } from '../../../../../../Shared/Components';
import { getInstallationStatus } from '../../../../../../Service';
import ThirdPartyAppModal from '../../ProductUpsell/Components/ThirdPartyAppModal';

const RenderComponent = (props) => {
  const { latestConfigSetting, updateData, setSettingType, setIsAppInstalled, isAppInstalled, isAppIntegrated, setIsAppIntegrated, appInstallationUrl,
    setAppInstallationUrl, setIsCheckStatus, setIsCheckStatusDescription, systemSetting, selectedWidgetType } = useContext(PageData);
  const { setting, id, widgetId, parentWidgetId, target, componentType, collections, max, syncSettings } = props.data;
  const [tags, setTags] = useState([]);
  
  const [isModalactive, setisModalActive] = useState(false);
  const [modalTitle, setmodalTitle] = useState("");
  const [ismodalTitleShow, setismodalTitleShow] = useState("");
  const [selectedProductType, setselectedProductType] = useState("");
  const [selectedProduct, setselectedProduct] = useState("");
  const [modalLogo, setmodalLogo] = useState("");
  const [loading, setLoading] = useState(false);
  const [selectedPreviousProduct, setSelectedPreviousProduct] = useState("");

  useEffect(() => {
    const targetedValue = getTargetedValue(latestConfigSetting, `${target}.${setting.target}`, widgetId, parentWidgetId, componentType, id);
    const tags = targetedValue?.length > 0 ? targetedValue : [];
    setTags(tags);
    if(latestConfigSetting?.destinationWidgetId) {
      delete latestConfigSetting.destinationWidgetId;
      updateData(latestConfigSetting);
    }
  }, [latestConfigSetting, target, widgetId, parentWidgetId, componentType]);
  
  useEffect(() => {
    const targetedValue = getTargetedValue(latestConfigSetting, `${target}.${setting.target}`, widgetId, parentWidgetId, componentType, id);
    if(ProductDisplayTypes.includes(targetedValue)) {
      setselectedProduct(targetedValue);
    }
  }, []);

  const handleChange = (e, target, dependedTarget, fieldsOrOptions, type, parentTarget) => {
    let value = e;
    const cloneSettings = CreatedNewObj(latestConfigSetting);
    let updatedSettings = updateTargetedValue(cloneSettings, target, value, widgetId, parentWidgetId, componentType, id);
    
    if(dependedTarget) {
      if (selectedWidgetType === WidgetType.Banner && type && type === ComponentType.Checkbox) {
        // If banner with 'display button' is selected, then update the category value is 'CLICKABLE' otherwise 'INFORMATIONAL'
        let category = WidgetCategoryType.informational;
        if(value) {
          category = WidgetCategoryType.clickable;
        }
        updatedSettings = updateTargetedValue(updatedSettings, dependedTarget, category, widgetId, parentWidgetId, componentType, id);
        
        // If syncSettings is true, then update the target value with the value of the sync block.
        if(syncSettings) {
          updatedSettings = syncComponentSettings(updatedSettings, dependedTarget, category, parentWidgetId, widgetId, componentType, syncSettings);
        } 
      } 

      // when dropdown is change for timer then update the dependent target value
      if (type && type === ComponentType.Dropdown && fieldsOrOptions[0]?.dependedValue) {
        let dependedValue = fieldsOrOptions[0].dependedValue;
        if (value === fieldsOrOptions[0].value) {
          dependedValue = fieldsOrOptions[0].dependedValue
        } else {
          dependedValue = fieldsOrOptions[1].dependedValue
        }
        updatedSettings = updateTargetedValue(updatedSettings, dependedTarget, dependedValue, widgetId, parentWidgetId, componentType, id);

        // If syncSettings is true, then update the target value with the value of the sync block.
        if (syncSettings) {
          updatedSettings = syncComponentSettings(updatedSettings, dependedTarget, dependedValue, parentWidgetId, widgetId, componentType, syncSettings);
        }
      }
    }

    // If syncSettings is true, then update the target value with the value of the sync block.
    if(syncSettings) {
      updatedSettings = syncComponentSettings(updatedSettings, target, value, parentWidgetId, widgetId, componentType, syncSettings);
    }

    if (type && type === ComponentType.FieldGroup) {
      /*
       If any value changed for FieldGroup then update the value(dependentValue) in dependent target.
       Note: DependentValue is static value set in editor schema.
      */
      if (isArray(fieldsOrOptions)) {
        updatedSettings =  updateTargetedValue(updatedSettings, `${parentTarget}.${dependedTarget}`, fieldsOrOptions[0].dependedValue, widgetId, parentWidgetId);
      }

      // If syncSettings is true, then update the target value with the value of the sync block.
      if(syncSettings) {
        updatedSettings = syncComponentSettings(updatedSettings, `${parentTarget}.${dependedTarget}`, fieldsOrOptions[0].dependedValue, parentWidgetId, widgetId, componentType, syncSettings);
      }
    }

    if (ProductDisplayTypes.includes(value)) {
      setSettingType(value);
    }
    // if MAIN_PRODUCT option is selected
    // set image key value to empty string
    if (value === ConditionalKeys.MainProduct) {
      const imageTarget = target.slice(0, target.lastIndexOf('.') + 1) + 'imageURL';
      updatedSettings = updateTargetedValue(updatedSettings, imageTarget, '', widgetId, parentWidgetId, componentType, id);
      
      // If syncSettings is true, then update the target value with the value of the sync block.
      if(syncSettings) {
        updatedSettings = syncComponentSettings(updatedSettings, imageTarget, '', parentWidgetId, widgetId, componentType, syncSettings);
      }
    }

    
    if (updatedSettings) {
      updateData(updatedSettings);
    }

    if(Object.keys(ThirdPartyApps).includes(e)) {
      setselectedProduct((prev) => {
        setSelectedPreviousProduct(prev);
        return value;
      })

      setisModalActive(true);

      const appData = ThirdPartyApps[e];
      setmodalLogo(appData.modalLogo);
      setmodalTitle(appData.modalTitle);
      setismodalTitleShow(appData.showModalTitle);
      setselectedProductType(appData.productType);
    } else if (ProductDisplayTypes.includes(e)) {
      setselectedProduct((prev) => {
        setSelectedPreviousProduct(prev);
        return value;
      })
    }
  };

  const isModalShow = async (e) => {
    setisModalActive(e.isActive);

    if(e.isInstalled === false){
      // When any error message display on selection of wise/presonalised/recomatic, then user closes the model then selected product set as previous selected value  
      // const dependentTarget =`${target}.sectionSettings.product.productToDisplay`;
      const cloneSettings = await CreatedNewObj(latestConfigSetting);
      const updatedSettings = await updateTargetedValue(cloneSettings, `${target}.${setting.target}`, selectedPreviousProduct, widgetId, parentWidgetId, componentType, id);
      updateData(updatedSettings)
      // If selected product through any error then user close the model set setSelectedProduct as selectedPreviousProduct
      setselectedProduct(selectedPreviousProduct); 
      setSelectedPreviousProduct(selectedPreviousProduct);
    }
  }

  const handleTagsChange = (value) => {
    if (value.trim() !== '') {
      const nextSelectedTags = new Set([...tags]);

      if (nextSelectedTags.has(value)) {
        nextSelectedTags.delete(value);
      } else {
        nextSelectedTags.add(value);
      }

      setTags([...nextSelectedTags]);

      const tagString = [...nextSelectedTags];
      const cloneSettings = CreatedNewObj(latestConfigSetting);
      let updatedSettings = updateTargetedValue(cloneSettings, `${target}.${setting.target}`, tagString, widgetId, parentWidgetId, componentType, id);
      
      // If syncSettings is true, then update the target value with the value of the sync block.
      if(syncSettings) {
        updatedSettings = syncComponentSettings(updatedSettings, `${target}.${setting.target}`, tagString, parentWidgetId, widgetId, componentType, syncSettings);
      }

      updateData(updatedSettings);
    }
  };

  const handleClick = (e, target) => {
    let value = true;
    const cloneSettings = CreatedNewObj(latestConfigSetting);
    let updatedSettings = updateTargetedValue(cloneSettings, target, value, widgetId, parentWidgetId);
    // If syncSettings is true, then update the target value with the value of the syncked component.
    if(syncSettings) {
      updatedSettings = syncComponentSettings(updatedSettings, target, value, parentWidgetId, widgetId, componentType, syncSettings);
    }
    updateData(updatedSettings);
  }

  const handleRangeSliderChange = (value) => {
    const cloneSettings = CreatedNewObj(latestConfigSetting);
    let updatedSettings = updateTargetedValue(cloneSettings, `${target}.${setting.target}`, value, widgetId, parentWidgetId, componentType, id);

     // If syncSettings is true, then update the target value with the value of the sync block.
     if(syncSettings) {
      updatedSettings = syncComponentSettings(updatedSettings, `${target}.${setting.target}`, value, parentWidgetId, widgetId, componentType, syncSettings);
    }

    updateData(updatedSettings);
  }

  useEffect(() => {
    if (selectedProductType) {
      onCheckStatus()
    }
  }, [selectedProductType]);

  const onCheckStatus = async () => {
    setLoading(true);
    if (selectedProductType) {
      try {
        const res = await getInstallationStatus(selectedProductType)
        const { data } = res;
        const { installed, url, integrated } = data;
        setIsAppInstalled(installed);
        setAppInstallationUrl(url);
        setIsAppIntegrated(integrated);
        if (installed && integrated) {
          setIsCheckStatus(false);
          setIsCheckStatusDescription(false);
        }
        setLoading(false);
      } catch (error) {
        setAppInstallationUrl("");
        console.error(error);
        setIsAppInstalled(false);
        setLoading(false);
      }
    }
  };

  const targetChange = useMemo(() => {
    return {
      minQuantityEnabled: `${target}.minQuantity.enabled`,
      minQuantity: `${target}.minQuantity.value`,
      defaultQuantity: `${target}.defaultQuantity`,
      maxQuantityEnabled: `${target}.maxQuantity.enabled`,
      maxQuantity: `${target}.maxQuantity.value`,
      subTargetDefaultQuantity: 'defaultQuantity',
      subTargetMaxQuantity: 'maxQuantity.value',
      subTargetMinQuantity: 'minQuantity.value',
    };
  }, [target])

  const getMinQuantity = (subTarget) => {
    if (componentType === UpsellBlocks.PriceAndDiscount) {
      return 0;
    }
    // Request come for min quantity number field or default number field so return min quantity of default value
    else if (subTarget === targetChange.subTargetDefaultQuantity) {
      const isMinEnable = getTargetedValue(latestConfigSetting, targetChange.minQuantityEnabled, widgetId, parentWidgetId, componentType, id);
      if (isMinEnable) {
        // Return minimum quantity
        return getTargetedValue(latestConfigSetting, targetChange.minQuantity, widgetId, parentWidgetId, componentType, id);
      }
    }
    // Request come for max quantity number field to get min quantity value.
    else if (subTarget === targetChange.subTargetMaxQuantity) {
      // minimum quantity for max quantity number should be default quantity value
      return getTargetedValue(latestConfigSetting, targetChange.defaultQuantity, widgetId, parentWidgetId, componentType, id);
    }
    return 1;
  }

  const getMaxQuantity = (subTarget, dependedTarget) => {
    if (componentType === UpsellBlocks.PriceAndDiscount) {
      return getTargetedValue(latestConfigSetting, dependedTarget, widgetId, parentWidgetId, componentType, id) === 'PERCENTAGE' ? 100 : Infinity
    }
    // Request come for max quantity number field or default number field so return max quantity of default value
    if (subTarget === targetChange.subTargetDefaultQuantity) {
      const isMaxEnable = getTargetedValue(latestConfigSetting, targetChange.maxQuantityEnabled, widgetId, parentWidgetId, componentType, id);
      if (isMaxEnable) {
        return getTargetedValue(latestConfigSetting, targetChange.maxQuantity, widgetId, parentWidgetId, componentType, id);
      }
    }
    // Request come for min quantity number field then return default quantity value
    else if (subTarget === targetChange.subTargetMinQuantity) {
      return getTargetedValue(latestConfigSetting, targetChange.defaultQuantity, widgetId, parentWidgetId, componentType, id);
    }

    return Infinity;
  }

  const getSuffixValue = (subTarget) => {
    if (componentType === UpsellBlocks.PriceAndDiscount) {
      return getTargetedValue(latestConfigSetting, subTarget, widgetId, parentWidgetId, componentType, id) === 'PERCENTAGE' ? '%' : systemSetting?.currencySymbol ? systemSetting.currencySymbol : '$';
    } else {
      return;
    }
  }

  if (setting.conditional && !checkTargetConditions(latestConfigSetting, `${target}.${setting.target}`, setting.condition, widgetId, parentWidgetId, componentType, id)) {
    return null;
  } else {
    const conditionalOption = setting?.conditionalOption ? getConditionalOption(setting, latestConfigSetting, id, widgetId, parentWidgetId) : setting.options === 'fetchOptions' ? collections : setting.options;
    return (
      <>
        {setting.type === ComponentType.Checkbox && (
          <CheckBox
            label={setting.label}
            checked={getTargetedValue(latestConfigSetting, `${target}.${setting.target}`, widgetId, parentWidgetId, componentType, id)}
            onChange={e => handleChange(e, `${target}.${setting.target}`, `${target}.${setting.dependedTarget}`, '', setting.type)}
            showTooltip={setting.showTooltip}
            tooltipText={setting.tooltipText}
            helpText={setting.helpText}
            disabled={!setting.updateConditions || canUpdateField(latestConfigSetting, `${target}`, setting.updateConditions, widgetId, parentWidgetId, componentType, id) ? false : true}
          />
        )}

        {setting.type === ComponentType.TextField && (
          <TextInput
            label={setting.label}
            value={getTargetedValue(latestConfigSetting, `${target}.${setting.target}`, widgetId, parentWidgetId, componentType, id)}
            onChange={e => handleChange(e, `${target}.${setting.target}`)}
            showTooltip={setting.showTooltip}
            tooltipText={setting.tooltipText}
            multiline={setting.isMultiline}
            currentWidgets={{selectedWidgetType,latestConfigWidgets:latestConfigSetting.widgets,currentId:parentWidgetId,productUpsellWarningMessages:{"unique_name":t(setting.uniqueNameWaringMessage),"required":t(setting.requiredWaringMessage)}}}
            maxLimitWaringMessage={t(setting?.maxLimitWaringMessage)}
          />
        )}

        {setting.type === ComponentType.TextEditor && (
          <TextEditor
            label={setting.label}
            tooltipText={setting.tooltipText}
            defaultText={setting.defaultText}
            showTooltip={setting.showTooltip}
            value={getTargetedValue(latestConfigSetting, `${target}.${setting.target}`, widgetId, parentWidgetId, componentType, id)}
            onChange={(e) => handleChange(e, `${target}.${setting.target}`)}
          />
        )}

        {setting.type === ComponentType.Dropdown && (
          <div>
            <Dropdown
              showTooltip={setting.showTooltip}
              tooltipText={setting.tooltipText}
              label={setting.label}
              options={conditionalOption}
              onChange={e => handleChange(e, `${target}.${setting.target}`, `${target}.${setting.dependedTarget}`, setting.options , setting.type)}
              value={getTargetedValue(latestConfigSetting, `${target}.${setting.target}`, widgetId, parentWidgetId, componentType, id)}
              helpText={setting?.helpText && setting.helpText}
            />
          </div>
        )}

        {setting.type === ComponentType.TagEditor && (
          <TagInput
            onChange={handleTagsChange}
            tags={tags}
            label={setting.label}
            placeholder={t(setting.placeholder)}
            tooltipText={t(setting.tooltipText)}
            showTooltip={setting.showTooltip}
          />
        )}

        {setting.type === ComponentType.Button && (
          <Button onClick={(e) => handleClick(e, `${target}.${setting.target}`)} description={setting.description} bannerStatus={setting.bannerStatus} bannerText={setting.bannerText}>
            {t(setting.label)}
          </Button>
        )}


        {setting.type === ComponentType.Selector && (<ProductSelector data={{ ...props.data, target: `${target}.${setting.target}` }} />)}

        {setting.type === ComponentType.Slider && (
          <>
            <Slider
              label={t(setting.label)}
              value={getTargetedValue(latestConfigSetting, `${target}.${setting.target}`, widgetId, parentWidgetId, componentType, id)}
              onChange={handleRangeSliderChange}
              suffix={getTargetedValue(latestConfigSetting, `${target}.${setting.target}`, widgetId, parentWidgetId, componentType, id)}
              step={setting.step}
              min={setting.minVal}
              max={setting.maxVal || max}
              output
            />
          </>
        )}

        {setting.type === ComponentType.FieldGroup && (
          <FieldGroup
            label={t(setting.label)}
            fields={setting.fields}
            fieldsType={setting.fieldsType}
            onChange={e => handleChange(e, `${target}.${setting.target}`, setting.dependedTarget, setting.fields, setting.type, target)}
            value={getTargetedValue(latestConfigSetting, `${target}.${setting.target}`, widgetId, parentWidgetId, componentType, id)}
            showTooltip={setting.showTooltip}
            tooltipText={setting.tooltipText}
          />
        )}

        {setting.type === ComponentType.RadioButton && (
          <RadioButtonGroup
            label={t(setting.label)}
            options={setting.options}
            value={getTargetedValue(latestConfigSetting, `${target}.${setting.target}`, widgetId, parentWidgetId, componentType, id)}
            isShowTooltip={setting.showTooltip}
            tooltipContent={setting.tooltipText}
            onChange={e => handleChange(e, `${target}.${setting.target}`)}
          />
        )}

        {setting.type === ComponentType.NumberField && (
          <NumberInput
            label={setting.label}
            suffix={getSuffixValue(`${target}.${setting.suffix}`)}
            value={getTargetedValue(latestConfigSetting, `${target}.${setting.target}`, widgetId, parentWidgetId, componentType, id)}
            min={getMinQuantity(setting.target)}
            max={getMaxQuantity(setting.target, `${target}.${setting.dependedTarget}`)}
            onChange={e => handleChange(e, `${target}.${setting.target}`)}
            showTooltip={setting.showTooltip}
            tooltipText={setting.tooltipText}
            minLimitWaringMessage={t(setting?.minLimitWaringMessage)}
            maxLimitWaringMessage={t(setting?.maxLimitWaringMessage)}
          />
        )}

        {setting.type === ComponentType.DescriptionText && (
          <>
            <Label label={setting.label} />
            <Text><p style={{ color: '#6d7175', fontWeight: 400, fontSize: '0.4em' }}>{t(setting.description)}</p></Text>
          </>
        )}

        {
          isModalactive && 
          <ThirdPartyAppModal 
            isModalactive={isModalactive} 
            setisModalActive={e => isModalShow(e)} 
            onCheckStatus={onCheckStatus}  
            modalTitle={modalTitle} 
            modalLogo={modalLogo} 
            ismodalTitleShow={ismodalTitleShow} 
            appInstallationUrl={appInstallationUrl} 
            selectedProduct={selectedProduct} 
            isAppInstalled={isAppInstalled} 
            isAppIntegrated={isAppIntegrated} 
            loading={loading} 
          /> 
        }
      </>
    )
  }
}

export default RenderComponent;

