import React from 'react'
import styles from './Subheader.module.css'
import { t } from 'i18next';

const Subheader = ({ label }) => {
    return (
        <div className={styles.subheader_container} >
            {t(label)}
        </div>
    )
}

export default Subheader;